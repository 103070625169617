import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { AppConfigService } from '../app.config.service';

@Injectable({ providedIn: 'root' })
export class OAuthWrapperService {
  private readonly providerKey = 'idp';
  private initializedFlow: 'password' | 'flynex' | undefined;

  constructor(
    private oauthService: OAuthService,
    private oauthStorage: OAuthStorage,
    private appConfig: AppConfigService
  ) {
    this.initialize().catch((err) => console.error(err));
  }

  async loginPasswordFlow(username: string, password: string): Promise<void> {
    await this.initialize('password');
    await this.oauthService.fetchTokenUsingPasswordFlow(username, password);
  }

  async loginFlynexFlow(): Promise<void> {
    await this.initialize('flynex');

    await this.oauthService.initLoginFlowInPopup({
      height: 800,
      width: 600,
    });
  }

  signOut(): void {
    this.oauthService.logOut();
    this.oauthStorage.removeItem(this.providerKey);
  }

  async refreshTokenIfNeeded(): Promise<void> {
    if (this.oauthService.hasValidAccessToken()) {
      return;
    }

    await this.oauthService.refreshToken();
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  private async initialize(provider?: string | undefined): Promise<void> {
    provider ||= this.oauthStorage.getItem(this.providerKey) ?? undefined;

    if (!provider) {
      return;
    }

    let specificConfig: AuthConfig;

    switch (provider) {
      case 'password':
        if (this.initializedFlow === 'password') {
          return;
        }

        specificConfig = this.getPasswordFlowConfig();
        break;

      case 'flynex':
        if (this.initializedFlow === 'flynex') {
          return;
        }

        specificConfig = this.getFlynexFlowConfig();
        break;

      default:
        throw new Error(`Not implemented provider ${provider}`);
    }

    this.oauthService.configure({
      issuer: this.appConfig.oauthConfig.identityUrl,
      clientId: this.appConfig.oauthConfig.clientId,
      dummyClientSecret: this.appConfig.oauthConfig.clientSecret,
      ...specificConfig,
    });

    await this.oauthService.loadDiscoveryDocument();

    this.initializedFlow = provider;
    this.oauthStorage.setItem(this.providerKey, provider);
  }

  private getPasswordFlowConfig(): AuthConfig {
    return {
      oidc: false, // We don't need id token
      scope: 'offline_access',
    } as AuthConfig;
  }

  private getFlynexFlowConfig(): AuthConfig {
    return {
      responseType: 'code',
      oidc: false, // We don't need id token
      scope: 'offline_access',
      redirectUri: `${location.origin}/assets/static/oauth-login.html`,
      customQueryParams: {
        acr_values: `idp:${this.appConfig.oauthFlynexConfig.id}`,
      },
    } as AuthConfig;
  }
}
