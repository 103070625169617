import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import app from 'firebase/app';
import { mapValues, random, times, values } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { isNotNil } from '../../utils/is-not-nil';
import { AppConfigService } from '../app.config.service';
import { Dict } from '../models/dict';
import { RegisterUserRequest, User, UserRole } from '../models/user';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // https://stackoverflow.com/questions/37517208/firebase-kicks-out-current-user/38013551#38013551
  private secondaryApp = app.initializeApp(this.appConfig.firebaseConfig, 'AnotherInstance');

  private anonymousHeaders = {
    ['X-API-Key']: this.appConfig.apiKey!,
  };

  constructor(
    private db: DatabaseService,
    private appConfig: AppConfigService,
    private http: HttpClient
  ) {}

  getUser(userId: string): Observable<User> {
    return this.db.users
      .get(userId)
      .valueChanges()
      .pipe(
        filter(isNotNil),
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  getUserList({ organizationId }: { organizationId?: string } = {}): Observable<User[]> {
    return this.db.users.getList().pipe(
      map((users) =>
        organizationId
          ? users
              .filter((u) => u.organizations?.[organizationId] != null)
              .sort((a, b) => a.name?.localeCompare(b.name))
          : users
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  getUserMissionsCount() {
    return this.db.userMissions().pipe(
      map((userMissionsDict) =>
        mapValues(userMissionsDict, (userMissions) => values(userMissions).length)
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  getUsersDictionary(): Observable<Dict<User>> {
    return this.db.users.getList().pipe(
      map((users) =>
        (users || []).reduce((acc, curr) => {
          acc[curr.uid] = curr;
          return acc;
        }, {} as Dict<User>)
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  async createUser(email: string, name: string, role: UserRole, orgId: string) {
    const randomPassword = times(20, () => random(35).toString(36)).join('');

    const { user: newUser } = await this.secondaryApp
      .auth()
      .createUserWithEmailAndPassword(email, randomPassword);

    this.secondaryApp.auth().signOut();

    if (newUser == null) {
      throw new Error('Account already exists or the password is invalid.');
    }

    await newUser.updateProfile({
      displayName: name,
      photoURL: '',
    });

    await this.updateUser({
      uid: newUser.uid,
      name: newUser.displayName!,
      email: newUser.email!,
    });

    return this.updateUserOrganizations(newUser.uid, orgId, role);
  }

  updateUser({ name, email, uid }: Pick<User, 'uid'> & Partial<Pick<User, 'name' | 'email'>>) {
    const updatedUser: Pick<User, 'uid'> & Partial<User> = { uid };
    if (name != null) {
      updatedUser.name = name;
    }
    if (email != null) {
      updatedUser.email = email;
    }
    return this.db.users.update(updatedUser);
  }

  async updateUserOrganizations(uid: string, organizationId: string, role: UserRole) {
    if (role === UserRole.Deleted) {
      await this.db.organization(organizationId).user(uid).remove();
    } else {
      await this.db.organization(organizationId).user(uid).set();
    }

    await this.db.user(uid).organization(organizationId).setRole(role);
  }

  async deleteUser(uid: string, organizationId: string) {
    await this.updateUserOrganizations(uid, organizationId, UserRole.Deleted);

    const groupsObject = this.db.groups.getDict();
    const groups = await groupsObject.pipe(take(1)).toPromise();
    const userGroupIds = Object.entries(groups || {})
      .filter(([_, group]) => group.users?.[uid])
      .map(([id, _]) => id);
    const removeUserGroups = userGroupIds.map((groupId) =>
      this.db.groupUser(groupId, uid).remove()
    );

    return Promise.all(removeUserGroups);
  }

  async registerUser(model: RegisterUserRequest): Promise<void> {
    await this.http
      .post(`${this.appConfig.restApiUrl}users/register`, model, { headers: this.anonymousHeaders })
      .toPromise();
  }
}
