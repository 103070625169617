import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NbMenuService, NbToastrService } from '@nebular/theme';
import { format } from 'date-fns';
import { FileSaverService } from 'ngx-filesaver';
import { Observable, Subject, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  share,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { MissionService } from 'src/app/services/mission.service';
import { Destroyable, MixinRoot } from '../../../../utils/mixins';
import { ConfirmDialogService } from '../../../components/confirm-dialog/confirm-dialog.service';
import { MuxerService, VideoSourceModel, VideoStreamModel } from '../../../services/muxer.service';
import { StateService } from '../../../services/state.service';
import { STREAM_MENU_TAG, StreamMenuItem } from './stream-context-menu.component';

@Component({
  selector: 'mk-swimlanes',
  templateUrl: './swimlanes.component.html',
  styleUrls: ['./swimlanes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwimlanesComponent extends Destroyable(MixinRoot) implements OnInit {
  streamClicked$ = new Subject<VideoStreamModel>();
  videoSources$: Observable<VideoSourceModel[]> | undefined;
  selectedStreamId$: Observable<string | null> | undefined;

  constructor(
    private state: StateService,
    private menuService: NbMenuService,
    private confirm: ConfirmDialogService,
    private toastr: NbToastrService,
    private muxerService: MuxerService,
    private fileSaver: FileSaverService,
    private missionService: MissionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.videoSources$ = this.state.getCurrentMisssionVideoSources();
    this.selectedStreamId$ = this.state.videoStreamId;

    this.streamClicked$.pipe(takeUntil(this.destroyed$)).subscribe((stream) => {
      this.state.setVideoStream(stream);
      this.missionService.liveStreamEventChange$.next();
    });

    const streamMenuClicked$ = this.menuService.onItemClick().pipe(
      filter(({ tag }) => tag === STREAM_MENU_TAG),
      map(({ item }) => item as StreamMenuItem),
      share()
    );

    const downloadStream$ = streamMenuClicked$.pipe(
      filter((item) => item.type === 'download'),
      withLatestFrom(this.state.getCurrentMissionId()),
      switchMap(([{ stream }, missionId]) =>
        this.muxerService.downloadVideo(missionId, stream.id).pipe(
          map((blob) => ({ success: true, message: 'Success', streamEnd: stream.streamEnd, blob })),
          catchError((err: any) =>
            of({
              success: false,
              streamEnd: stream.streamEnd,
              message: err?.error ?? err?.message ?? err?.toString() ?? JSON.stringify(err),
              blob: null,
            })
          )
        )
      ),
      share()
    );

    downloadStream$
      .pipe(
        filter(({ success }) => success),
        takeUntil(this.destroyed$)
      )
      .subscribe(({ streamEnd, blob }) => {
        this.fileSaver.save(blob!, `${this.streamName(streamEnd!)}.mp4`);
      });

    downloadStream$
      .pipe(
        filter(({ success }) => !success),
        takeUntil(this.destroyed$)
      )
      .subscribe(({ message }) => {
        this.toastr.danger(message, 'Error');
      });

    const deleteStream$ = streamMenuClicked$.pipe(
      filter((item) => item.type === 'delete'),
      switchMap(({ stream }) =>
        this.confirm
          .prompt(
            'Delete stream?',
            `This will delete ${this.streamName(stream.streamStart)} stream`,
            'Delete'
          )
          .pipe(map((confirmed) => ({ confirmed, stream })))
      ),
      filter(({ confirmed }) => confirmed),
      withLatestFrom(this.state.getCurrentMissionId()),
      switchMap(([{ stream }, missionId]) =>
        this.muxerService.removeStream(missionId, stream.id).pipe(
          mapTo({ success: true, message: 'Stream deleted successfully', streamId: stream.id }),
          catchError((err: any) =>
            of({
              streamId: stream.id,
              success: false,
              message: err?.error ?? err?.message ?? err?.toString() ?? JSON.stringify(err),
            })
          )
        )
      ),
      share()
    );

    deleteStream$
      .pipe(
        filter(({ success }) => success),
        switchMap(({ message, streamId }) =>
          this.state.getCurrentVideoSource().pipe(
            map((source) => ({ source, message, streamId })),
            take(1)
          )
        ),
        withLatestFrom(this.state.videoStreamId),
        takeUntil(this.destroyed$)
      )
      .subscribe(([{ message, streamId, source }, currentStreamId]) => {
        this.toastr.success(message, 'Success');

        if (streamId !== currentStreamId) {
          return;
        }
        if (source == null) {
          return;
        }
        const streamIndex = source.streams.findIndex((stream) => stream.id === streamId);
        if (streamIndex === 0 || streamIndex === -1) {
          this.state.setVideoStream(source.streams[0]);
          return;
        }
        let nextStreamIndex = streamIndex - 1;
        if (nextStreamIndex < 0) {
          nextStreamIndex = 0;
        }

        this.state.setVideoStream(source.streams[nextStreamIndex]);
      });

    deleteStream$
      .pipe(
        filter(({ success }) => success === false),
        takeUntil(this.destroyed$)
      )
      .subscribe(({ message }) => {
        this.toastr.danger(message, 'Error');
      });
  }

  trackById(index: number, item: { id: string }) {
    return item.id;
  }

  streamName(streamStart: number) {
    return format(streamStart, 'M/D/YY, h:mm A');
  }
}
