import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbBadgePosition, NbComponentStatus, NbIconConfig } from '@nebular/theme';
import { delay, filter, map, takeUntil } from 'rxjs/operators';
import { isNotNil } from '../../../utils/is-not-nil';
import { Destroyable, MixinRoot } from '../../../utils/mixins';

function convertToBoolProperty(val: any): boolean {
  if (typeof val === 'string') {
    val = val.toLowerCase().trim();

    return val === 'true' || val === '';
  }

  return !!val;
}
type NbNullableInput = string | null | undefined;
type NbBooleanInput = boolean | NbNullableInput;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nb-tab',
  template: `
    <ng-container *ngIf="init">
      <ng-content></ng-content>
    </ng-container>
  `,
})
export class NbTabComponent {
  @Input() tabTitle: string | undefined;

  @Input() tabSubtitle?: string;

  @Input() tabId: string | undefined;

  @Input() tabIcon: string | NbIconConfig | undefined;

  @Input('disabled')
  @HostBinding('class.disabled')
  get disabled(): boolean {
    return this.disabledValue;
  }
  set disabled(val: boolean) {
    this.disabledValue = convertToBoolProperty(val);
  }
  static ngAcceptInputType_disabled: NbBooleanInput;

  @Input()
  set responsive(val: boolean) {
    this.responsiveValue = convertToBoolProperty(val);
  }
  get responsive() {
    return this.responsiveValue;
  }
  static ngAcceptInputType_responsive: NbBooleanInput;

  @Input() route: string | undefined;

  @HostBinding('class.content-active')
  activeValue = false;

  responsiveValue = false;
  disabledValue = false;

  @Input()
  get active() {
    return this.activeValue;
  }
  set active(val: boolean) {
    this.activeValue = convertToBoolProperty(val);
    if (this.activeValue) {
      this.init = true;
    }
  }
  static ngAcceptInputType_active: NbBooleanInput;

  @Input()
  set lazyLoad(val: boolean) {
    this.init = convertToBoolProperty(val);
  }
  static ngAcceptInputType_lazyLoad: NbBooleanInput;

  @Input() badgeText: string | undefined;

  @Input() badgeStatus: NbComponentStatus = 'basic';

  @Input() badgePosition: NbBadgePosition | undefined;

  init = false;
}

export interface NbTabChangeEvent {
  index: number;
  tab: NbTabComponent;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nb-tabset',
  styleUrls: ['./tabset.component.scss'],
  template: `
    <ul class="tabset">
      <li
        *ngFor="let tab of tabs; let idx = index"
        (click)="selectTab(tab, idx)"
        (keyup.space)="selectTab(tab, idx)"
        (keyup.enter)="selectTab(tab, idx)"
        [class.responsive]="tab.responsive"
        [class.active]="tab.active"
        [class.disabled]="tab.disabled"
        [attr.tabindex]="tab.disabled ? -1 : 0"
        class="tab"
      >
        <a href (click)="$event.preventDefault()" tabindex="-1" class="tab-link">
          <nb-icon *ngIf="tab.tabIcon" [config]="tab.tabIcon"></nb-icon>
          <ng-container
            *ngTemplateOutlet="
              tabHeaderTemplate ? tabHeaderTemplate : defaulTabHeaderTemplate;
              context: { $implicit: tab }
            "
          ></ng-container>
        </a>
        <nb-badge
          *ngIf="tab.badgeText"
          [text]="tab.badgeText"
          [status]="tab.badgeStatus"
          [position]="tab.badgePosition"
        >
        </nb-badge>
      </li>
    </ul>
    <ng-content select="nb-tab"></ng-content>
    <ng-template #defaulTabHeaderTemplate let-tab>
      <div class="tab-header">
        <span *ngIf="tab.tabTitle" class="tab-text">{{ tab.tabTitle }}</span>
        <span *ngIf="tab.tabSubtitle" class="tab-text">{{ tab.tabSubtitle }}</span>
      </div>
    </ng-template>
  `,
})
export class NbTabsetComponent extends Destroyable(MixinRoot) implements AfterContentInit {
  @Input() tabHeaderTemplate: TemplateRef<any> | undefined;

  @ContentChildren(NbTabComponent) tabs: QueryList<NbTabComponent> | undefined;

  @HostBinding('class.full-width')
  fullWidthValue = false;

  @Input()
  set fullWidth(val: boolean) {
    this.fullWidthValue = convertToBoolProperty(val);
  }
  static ngAcceptInputType_fullWidth: NbBooleanInput;

  @Input() routeParam: string | undefined;

  @Output() changeTab = new EventEmitter<NbTabChangeEvent>();

  constructor(private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngAfterContentInit() {
    this.route.params
      .pipe(
        map((params: any) => {
          const tabs = this.tabs!.toArray();
          const selectedTabIndex = tabs.findIndex((tab) =>
            this.routeParam ? tab.route === params[this.routeParam] : tab.active
          );
          const selectedTab = selectedTabIndex === -1 ? null : tabs[selectedTabIndex];
          return selectedTab ? { selectedTabIndex, selectedTab } : null;
        }),
        delay(0),
        map((tabWithIndex) => {
          if (tabWithIndex) {
            return tabWithIndex;
          }
          if (this.tabs!.length === 0) {
            return null;
          }
          return {
            selectedTab: this.tabs!.first,
            selectedTabIndex: 0,
          };
        }),
        filter(isNotNil),
        takeUntil(this.destroyed$)
      )
      .subscribe(({ selectedTab, selectedTabIndex }) => {
        this.selectTab(selectedTab, selectedTabIndex);
        this.changeDetectorRef.markForCheck();
      });
  }

  selectTab(selectedTab: NbTabComponent, selectedTabIndex: number) {
    if (!selectedTab.disabled) {
      this.tabs!.forEach((tab) => (tab.active = tab === selectedTab));
      this.changeTab.emit({ tab: selectedTab, index: selectedTabIndex });
    }
  }
}
