<nb-actions size="small">
  <nb-action icon="menu" (click)="toggleSidebar()"></nb-action>
  <nb-action>
    <div class="mk-logo" (click)="goToHome()">
      <img src="assets/images/mk-logo-large.png" class="logo-image" />
      <span class="mission-text">Mission</span>
      <span class="keeper-text">Keeper</span>
    </div>
  </nb-action>
  <nb-action link="/pages/dashboard" icon="calendar-outline"> </nb-action>
</nb-actions>

<span class="flex-fill"></span>

<nb-actions *ngIf="layoutService.showToggles | async">
  <nb-action (click)="layoutService.leftColumn.toggle()">
    <nb-icon pack="mk" icon="sidebar"
      [ngClass]="{ inactive: (layoutService.leftColumn.isVisible() | async) === false }"></nb-icon>
  </nb-action>
  <nb-action (click)="layoutService.rightColumn.toggle()">
    <nb-icon pack="mk" icon="sidebar" class="right-sidebar"
      [ngClass]="{ inactive: (layoutService.rightColumn.isVisible() | async) === false }"></nb-icon>
  </nb-action>
</nb-actions>

<span class="flex-fill"></span>

<nb-actions size="small">
  <nb-action *ngIf="appConfig.firstNetAppCatalogLogo">
    <img src="assets/images/attfn_pos.png" class="header-fn-logo" />
  </nb-action>
  <nb-action *ngIf="userMenu$ | async as userMenu">
    <nb-user class="header-user-container" [nbContextMenu]="userMenu" [name]="userName | async"
      [nbContextMenuTag]="menuTag"></nb-user>
  </nb-action>
</nb-actions>