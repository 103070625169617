import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NbContextMenuDirective, NbMenuItem } from '@nebular/theme';
import { VideoStreamModel } from '../../../services/muxer.service';

export const STREAM_MENU_TAG = 'stream-menu';

export interface StreamMenuItem extends NbMenuItem {
  stream: VideoStreamModel;
  type: 'delete' | 'download';
}

@Component({
  selector: 'mk-stream-context-menu',
  template: `
    <div
      *ngIf="menuItems.length > 0"
      [nbContextMenu]="menuItems"
      nbContextMenuTag="my-context-menu"
      nbContextMenuTrigger="noop"
      [nbContextMenuTag]="STREAM_MENU_TAG"
      (contextmenu)="openMenu()"
    >
      <ng-content></ng-content>
    </div>
  `,
})
export class StreamContextMenuComponent implements OnInit {
  STREAM_MENU_TAG = STREAM_MENU_TAG;

  @HostListener('document:click')
  closeOnClick() {
    this.menu?.hide();
  }

  @HostListener('document:contextmenu', ['$event'])
  closeOnRightClick(e: MouseEvent) {
    if (this._element.nativeElement.querySelector('.stream-title') !== e.target) {
      this.menu?.hide();
    }
  }

  @ViewChild(NbContextMenuDirective) menu: NbContextMenuDirective | undefined;

  menuItems: StreamMenuItem[] = [];

  @Input() set stream(value: VideoStreamModel) {
    const deleteItem: StreamMenuItem = { title: 'Delete', type: 'delete', stream: value };
    const downloadItem: StreamMenuItem = { title: 'Download', type: 'download', stream: value };
    this.menuItems = value.isActive ? [] : [deleteItem, downloadItem];
  }

  constructor(private _element: ElementRef) {}

  ngOnInit() {}

  openMenu() {
    this.menu?.show();
    return false;
  }
}
