import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../app.config.service';
import { OAuthTokenService } from './oauth-token.service';

class HttpError {
  readonly code: string;
  readonly message: string;

  constructor(error: { code: string; message: string }) {
    this.code = error.code;
    this.message = error.message;
  }

  toString(): string {
    return this.message;
  }
}

@Injectable({
  providedIn: 'root',
})
export class IdentityServerService {
  constructor(
    private appConfig: AppConfigService,
    private http: HttpClient,
    private oauthTokenService: OAuthTokenService
  ) {}

  async resetPassword(email: string): Promise<void> {
    await this.http
      .post(`${this.appConfig.identityServerUrl}reset-password`, {
        email,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (!err.error) {
            return throwError(err);
          }

          return throwError(new HttpError(err.error));
        })
      )
      .toPromise();
  }

  async getFirebaseCustomToken(): Promise<string | undefined> {
    const authToken = await this.oauthTokenService.getToken();
    if (!authToken) {
      return undefined;
    }

    const headers = new HttpHeaders({
      ['Authorization']: `Bearer ${authToken!}`,
    });

    const result = await this.http
      .get<string>(`${this.appConfig.identityServerUrl}firebase/token`, {
        headers,
        responseType: 'text' as any,
      })
      .toPromise();

    return result;
  }
}
