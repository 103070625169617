<nb-card>
  <nb-card-header>{{ dialogTitle }}</nb-card-header>
  <nb-card-body>
    <form #missionForm="ngForm">
      <div class="form-control-group">
        <label>Mission Name (Max 200)</label>
        <input
          fullWidth
          maxlength="200"
          name="missionTitle"
          #nameControl="ngModel"
          nbInput
          [(ngModel)]="missionTitle"
          placeholder="Mission Name"
          required
          type="text"
        />
        <p class="caption status-danger">
          <ng-container *ngIf="nameControl.errors?.required">Name is required!</ng-container>
        </p>
        <p class="caption status-danger" *ngIf="nameControl.errors?.maxlength">
          <ng-container *ngIf="nameControl.errors?.maxlength">
            Name should be less than 200 characters
          </ng-container>
        </p>
      </div>

      <div class="form-control-group">
        <label>Users</label>
        <ng-multiselect-dropdown
          [settings]="multiselectSettings"
          [data]="allUsers"
          name="selectedUsers"
          [(ngModel)]="selectedUsers"
          [placeholder]="'Invite people to the mission'"
          required
          #usersControl="ngModel"
        >
        </ng-multiselect-dropdown>
        <ng-container *ngIf="usersControl.invalid">
          <p class="caption status-danger" *ngIf="usersControl.errors?.required">
            Please select at least 1 option
          </p>
        </ng-container>
      </div>

      <!-- <div class="form-control-group">
        <label>Groups</label>
        <ng-multiselect-dropdown
          [settings]="multiselectSettings"
          [placeholder]="'Invite user groups to the mission'"
          [data]="allGroups"
          [(ngModel)]="selectedGroups"
          name="selectedGroups"
        >
        </ng-multiselect-dropdown>
      </div> -->

      <div class="form-control-group">
        <label>Duration</label>
        <div class="horizontal-group">
          <input
            nbInput
            name="startDate"
            [(ngModel)]="startDate"
            (ngModelChange)="checkDates($event)"
            [owlDateTime]="dt1"
            [owlDateTimeTrigger]="dt1"
            placeholder="Misson Time"
          />
          <input
            [min]="startDate"
            name="endDate"
            nbInput
            [(ngModel)]="endDate"
            [owlDateTime]="dt2"
            [owlDateTimeTrigger]="dt2"
            placeholder="Misson Time"
          />
        </div>
      </div>
      <owl-date-time #dt1></owl-date-time>
      <owl-date-time #dt2></owl-date-time>

      <div class="form-control-group">
        <label>Mission Location</label>
        <input
          fullWidth
          name="location"
          nbInput
          [(ngModel)]="location"
          placeholder="Location"
          type="text"
        />
      </div>

      <div class="form-control-group" style="display: none;">
        <label>Flight ID</label>
        <input
          fullWidth
          name="flightId"
          nbInput
          [(ngModel)]="flightId"
          placeholder="Flight ID"
          type="text"
          readonly
        />
      </div>

      <div class="form-control-group">
        <label>Mission Description</label>
        <input
          fullWidth
          name="description"
          nbInput
          [(ngModel)]="description"
          placeholder="Description"
          type="text"
        />
      </div>

      <div class="form-control-group" *ngIf="appConfigArchivesEnabled">
        <label>Enable Archives</label>
        <nb-toggle
          [checked]="archivesEnabled"
          (checkedChange)="archivesEnabled = $event"
          status="primary"
        ></nb-toggle>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <button (click)="dismiss()" nbButton status="primary">Cancel</button>
    <button (click)="submit()" [disabled]="!missionForm.valid" nbButton status="success">
      Save
    </button>
  </nb-card-footer>
</nb-card>
