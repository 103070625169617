import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { combineLatest, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { UserRole, USER_ROLE_METADATA } from '../models/user';
import { OrganizationService } from './organization.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Currently logged in user's profile service
 **/
export class ProfileService {
  get userId() {
    return this.auth.user.pipe(
      map((user) => (user ? user.uid : null)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  get user() {
    return this.userId.pipe(
      switchMap((userId) => (userId ? this.userService.getUser(userId) : of(null))),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  get isAdmin() {
    return this.userRole.pipe(map((role) => role === UserRole.Administrator));
  }

  get isSuperUser() {
    return this.user.pipe(
      map((user) =>
        Object.values(user?.organizations || {}).some((org) => org.role === UserRole.Superuser)
      )
    );
  }

  get isPendingApprovalOrGuest() {
    return this.userRole.pipe(
      map((role) => role === UserRole.PendingApproval || role === UserRole.Guest)
    );
  }

  get isDeleted() {
    return this.userRole.pipe(map((role) => role === UserRole.Deleted));
  }

  get userRole() {
    return combineLatest([this.user, this.organization.currentOrganizationId]).pipe(
      map(([user, organizationId]) => user?.organizations?.[organizationId]?.role ?? null),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  get avaliableRoles() {
    return this.isSuperUser.pipe(
      map((isSuperUser) => isSuperUser
          ? Object.keys(USER_ROLE_METADATA) as UserRole[]
          : Object.keys(USER_ROLE_METADATA).filter(r => r !== UserRole.Superuser) as UserRole[])
    )
  }

  constructor(
    private auth: AngularFireAuth,
    private organization: OrganizationService,
    private userService: UserService
  ) { }
}
