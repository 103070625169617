import { Injectable } from '@angular/core';
import { AngularFireDatabase, QueryFn } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import {
  GROUPS,
  MESSAGES,
  MISSIONS,
  MUXERS_BOX,
  ORGANIZATIONS,
  ORGANIZATION_GROUPS,
  ORGANIZATION_USERS,
  POIS,
  SKYCASTERS,
  TELEMETRY,
  USERS,
  USER_MISSIONS,
} from '../../utils/firebase-ref-paths';
import { ChatMessage } from '../models/chat-message';
import { Dict } from '../models/dict';
import { Flight, FlightCommand } from '../models/flight';
import { Group } from '../models/group';
import { Mission, MissionUser, PoiModel } from '../models/mission';
import { Muxer } from '../models/muxer';
import { Organization } from '../models/organization';
import { SkyCaster } from '../models/skycaster';
import { Telemetry } from '../models/telemetry';
import { User, UserOrganizations, UserRole } from '../models/user';
import { UserMissions } from '../models/user-mission';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  private groupList = this.db.list<Group>(GROUPS);
  private groupDict = this.db.object<Dict<Group>>(GROUPS);
  private messagesList = (queryFn?: QueryFn) => this.db.list<ChatMessage>(MESSAGES, queryFn);
  private missionsList = this.db.list<Mission>(MISSIONS);
  private muxerList = this.db.list<Muxer>(MUXERS_BOX);
  private muxerDict = this.db.object<Dict<Muxer>>(MUXERS_BOX);
  private telemetryList = (streamId: string, queryFn?: QueryFn) =>
    this.db.list<Telemetry>(`${TELEMETRY}/${streamId}`, queryFn);
  private userList = this.db.list<User>(USERS);
  private poisList = this.db.list<PoiModel>(POIS);

  constructor(private db: AngularFireDatabase) { }

  /** gets `/.info/connected` */
  connected = () => this.db.object<boolean>('.info/connected');

  // =========================================== GROUPS ===========================================
  groups = {
    /** Adds `<group>` to `/groups` */
    add: (group: Group) => this.groupList.push(group),
    /** Gets `/groups` */
    getDict: () => this.groupDict.valueChanges(),
    /** Removes `/groups/<id>` */
    remove: (id: string) => this.groupList.remove(id),
    /** Updates `/groups/<id>` */
    update: (id: string, group: Partial<Group>) => this.groupList.update(id, group),
  };
  groupUser = (groupId: string, uid: string) => ({
    /** Removes `/groups/<groupId>/users/<uid>` */
    remove: () => this.db.object(`${GROUPS}/${groupId}/users/${uid}`).remove(),
  });

  // ========================================== MESSAGES ==========================================
  messages = {
    /** Adds message to `/messages` */
    add: (message: ChatMessage) => this.messagesList().push(message),
    /** Gets `/messages` with `mission=<missionId>` */
    get: (missionId: string) =>
      this.messagesList((ref) =>
        ref.orderByChild('mission').equalTo(missionId).limitToLast(100)
      ).valueChanges(),
  };

  // ========================================== MISSIONS ==========================================
  missions = {
    /** Add `<mission>` to `/missions` */
    add: (mission: Mission) => this.missionsList.push(mission),
    /** Gets `/missions/<missionId>` */
    get: (missionId: string) => this.db.object<Mission>(`${MISSIONS}/${missionId}`),
    /** Removes `/missions/<missionId>` */
    remove: (missionId: string) => this.missionsList.remove(missionId),
    /** Updates `/missions/<missionId>` */
    update: (missionId: string, mission: Partial<Mission>) =>
      this.missionsList.update(missionId, mission),
  };
  missionUser = (missionId: string, uid: string) => ({
    /** Removes `/missions/<missionId>/users/<uid>` */
    remove: () => this.db.list<MissionUser>(`${MISSIONS}/${missionId}/users`).remove(uid),
  });

  // ========================================== POIS ==========================================
  pois = {
    /** Gets `/pois/<missionId>` */
    get: (missionId: string) =>
      this.db.list<PoiModel>(`${POIS}`, (ref) => ref.orderByChild('missionId').equalTo(missionId)),
  };

  // =========================================== MUXERS ===========================================
  muxer = {
    /** Adds `<muxer>` to `/muxers/box` */
    add: (muxer: Muxer) => this.muxerList.push(muxer),
    /** Gets `/muxers/box` */
    getDict: () => this.muxerDict.valueChanges().pipe(map((dict) => dict ?? {})),
    /** Removes `/muxers/box/<muxerId>` */
    remove: (muxerId: string) => this.muxerList.remove(muxerId),
    /** Gets `/muxers/box/<id>` */
    update: (id: string, muxer: Partial<Muxer>) => this.muxerList.update(id, muxer),
  };

  // ======================================== ORGANIZATIONS ========================================
  organizations = {
    /** Adds `<organization>` to `/organizations` */
    add: (organization: Organization) =>
      this.db.list<Organization>(ORGANIZATIONS).push(organization),
    /** Gets `/organizations/<id>` */
    get: (id: string) => this.db.object<Organization>(`${ORGANIZATIONS}/${id}`),
    /** Gets /organizations */
    getDict: () => this.db.object<Dict<Organization>>(ORGANIZATIONS),
    /** Removes `/organizations/<organizationId>` */
    remove: (organizationId: string) =>
      this.db.object<Organization>(`${ORGANIZATIONS}/${organizationId}`).remove(),
    /** Updates `/organizations/<organizationId>` */
    update: (organizationId: string, organization: Partial<Organization>) =>
      this.organizations.get(organizationId).update(organization),
  };
  organization = (organizationId: string) => ({
    users: {
      /** Removes `/organizationUsers/<organizationId>` */
      remove: () =>
        this.db.object<Organization>(`${ORGANIZATION_USERS}/${organizationId}`).remove(),
    },
    user: (uid: string) => ({
      /** Sets `/organizationUsers/<organizationId>/<uid>` to `true` */
      set: () => this.db.object<true>(`${ORGANIZATION_USERS}/${organizationId}/${uid}`).set(true),
      /** Removes `/organizationUsers/<organizationId>/<uid>` */
      remove: () => this.db.object<true>(`${ORGANIZATION_USERS}/${organizationId}/${uid}`).remove(),
    }),
    group: (groupId: string) => ({
      /** Sets `/organizationGroups/<organizationId>/<groupId>` to `true` */
      set: () =>
        this.db.object<true>(`${ORGANIZATION_GROUPS}/${organizationId}/${groupId}`).set(true),
      /** Removes `/organizationGroups/<organizationId>/<groupId>` */
      remove: () =>
        this.db.object<true>(`${ORGANIZATION_GROUPS}/${organizationId}/${groupId}`).remove(),
    }),
  });

  // =========================================== TELEMETRY ===========================================
  telemetry = {
    getAll: (streamId: string) => this.telemetryList(streamId).valueChanges(),
    getRange: (streamId: string, startAt: string, endAt: string) =>
      this.telemetryList(streamId, (ref) =>
        ref.orderByKey().startAt(startAt).endAt(endAt)
      ).valueChanges(),
  };

  // ============================================= USERS =============================================
  users = {
    /** Gets `/users/<uid>` */
    get: (uid: string) => this.db.object<User>(`${USERS}/${uid}`),
    /** Gets `/users` */
    getList: () => this.userList.valueChanges(),
    /** Updates `/users/<user.uid>` */
    update: (user: Partial<User> & Pick<User, 'uid'>) => this.userList.update(user.uid, user),
  };
  userMissions = () => this.db.object<Dict<UserMissions>>(`${USER_MISSIONS}`).valueChanges();
  user(uid: string) {
    return {
      /** Gets `/users/<uid>/organizations` */
      organizations: () =>
        this.db.object<UserOrganizations>(`${USERS}/${uid}/organizations`).valueChanges(),
      organization: (orgId: string) => ({
        /** Sets `/users/<uid>/organizations/<orgId>/role` */
        setRole: (role: UserRole) =>
          this.db.object<UserRole>(`${USERS}/${uid}/organizations/${orgId}/role`).set(role),
        /** Removes `users/<uid>/organizations/<orgId>` */
        remove: () => this.db.object<true>(`${USERS}/${uid}/organizations/${orgId}`).remove(),
      }),
      /** Gets `/userMissions/<uid>` */
      missions: () => this.db.object<Dict<true>>(`${USER_MISSIONS}/${uid}`).valueChanges(),
      mission: (missionId: string) => ({
        /** Sets `/userMissions/<uid>/<missionId>` to `true` */
        set: () => this.db.object<true>(`${USER_MISSIONS}/${uid}/${missionId}`).set(true),
        /** Removes `/userMissions/<uid>/<missionId>` */
        remove: () => this.db.object<true>(`${USER_MISSIONS}/${uid}/${missionId}`).remove(),
      }),
    };
  }
  // ============================================= FLIGHT =============================================
  flight = {
    /** Gets `/users/<uid>/flight/<flight_id>` */
    get: (uid: string, flight_id: string) =>
      this.db.object<Flight>(`${SKYCASTERS}/${uid}/flights/${flight_id}`),
    /** Gets `/users` */
    getList: () => this.userList.valueChanges(),
    /** Updates `/users/<user.uid>` */
    update: (user: Partial<User> & Pick<User, 'uid'>) => this.userList.update(user.uid, user),
    getCommand: (uid: string, flight_id: string) =>
      this.db.object<Dict<FlightCommand>>(`${SKYCASTERS}/${uid}/flights/${flight_id}/Command`),
    getCommandUpdate: (uid: string, flight_id: string) =>
      this.db.object<Dict<number>>(`${SKYCASTERS}/${uid}/flights/${flight_id}/Command`),
  };

  // ======================================== SKYCASTER ========================================
  skycasters = {
    /** Adds `<skycaster>` to `/skycasters` */
    add: (boxId: string, skycaster: SkyCaster) =>
      this.db.object<SkyCaster>(`${SKYCASTERS}/${boxId}`).set(skycaster),
    /** Gets `/skycasters/<id>` */
    get: (boxId: string) => this.db.object<SkyCaster>(`${SKYCASTERS}/${boxId}`),
    /** Gets /skycasters */
    getList: () => this.db.object<Dict<SkyCaster>>(SKYCASTERS),
    /** Removes `/skycasters/<skycasterId>` */
    remove: (boxId: string) =>
      this.db.object<SkyCaster>(`${SKYCASTERS}/${boxId}`).remove(),
    /** Updates `/skycasters/<skycasterId>` */
    update: (boxId: string, skycaster: Partial<SkyCaster>) =>
      this.skycasters.get(boxId).update(skycaster),
  };
}
