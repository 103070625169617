import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AUTH_URLS } from '../authentication/routing/auth-urls';
import { OAuthWrapperService } from './oauth-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthTokenService {
  constructor(private oauthService: OAuthWrapperService, private router: Router) {}

  async getToken(): Promise<string | undefined> {
    try {
      await this.oauthService.refreshTokenIfNeeded();
      return this.oauthService.getAccessToken()!;
    } catch {
      // If any error happened, we consider current authentication broken by some reasons
      // and navigate user to login page
      this.navigateToLoginPage();
      return '';
    }
  }

  private navigateToLoginPage(): void {
    this.router.navigate(AUTH_URLS.SIGN_IN());
  }
}
