import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { chain, first, isEmpty, isEqual } from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { filterForNotIsNil, isNotNil } from '../../utils/is-not-nil';
import { AppConfigService } from '../app.config.service';
import { Keyed, MuxerWithKey } from '../models/keyed';
import { MissionStream } from '../models/mission';
import { Muxer } from '../models/muxer';
import { DatabaseService } from './database.service';
import { FetchMissionService } from './fetch-mission.service';
import { UserService } from './user.service';

type MissionStreamWithStreamStart = Omit<MissionStream, 'streamStart'> &
  Required<Pick<MissionStream, 'streamStart'>>;
function hasStreamsStart(e: MissionStream): e is Keyed<MissionStreamWithStreamStart> {
  return !!e.streamStart;
}

export interface VideoStreamModel {
  id: string;
  dashUrl: string;
  hlsUrl: string;
  streamStart: number;
  streamEnd?: number;
  author: string;
  device: string;
  isActive: boolean;
  streamType: 'h264elementary' | 'mpegts' | undefined;
  hardware: 'ios' | 'dji' | 'box' | 'android' | 'undefined' | undefined;
}

export class VideoSourceModel {
  constructor(
    public readonly author: string,
    public type: 'box' | 'mobile',
    public readonly title: string,
    public readonly device: string | null,
    public readonly streams: VideoStreamModel[]
  ) { }

  public get isActive(): boolean {
    return this.streams.some((stream) => stream.isActive);
  }
}

@Injectable({
  providedIn: 'root',
})
export class MuxerService {
  private protocol: string = this.appConfig.urlProtocol;

  private headers$ = combineLatest([this.auth.idToken, of(this.appConfig.apiKey)]).pipe(
    take(1),
    filterForNotIsNil(),
    map(([authToken, apiKey]) => ({
      ['Authorization']: `Bearer ${authToken!}`,
      ['X-API-Key']: apiKey!,
    }))
  );

  constructor(
    private appConfig: AppConfigService,
    private db: DatabaseService,
    private auth: AngularFireAuth,
    private http: HttpClient,
    private fetchMissionService: FetchMissionService,
    private userService: UserService
  ) { }

  create(muxer: Muxer) {
    return this.db.muxer.add({
      dashUrl: muxer.dashUrl,
      hlsUrl: muxer.hlsUrl,
      inputUrl: muxer.inputUrl,
    });
  }

  update(muxer: MuxerWithKey) {
    return this.db.muxer.update(muxer.key, {
      dashUrl: muxer.dashUrl,
      hlsUrl: muxer.hlsUrl,
      inputUrl: muxer.inputUrl,
    });
  }

  remove(muxerId: string): Promise<void> {
    return this.db.muxer.remove(muxerId);
  }

  removeStream(missionId: string, streamId: string) {
    return this.headers$.pipe(
      filterForNotIsNil(),
      switchMap((headers) =>
        this.http.delete(`${this.appConfig.restApiUrl}missions/${missionId}/streams/${streamId}`, {
          headers,
        })
      )
    );
  }

  downloadVideo(missionId: string, streamId: string) {
    return this.headers$.pipe(
      filterForNotIsNil(),
      switchMap((headers) =>
        this.http.get(
          `${this.appConfig.restApiUrl}missions/${missionId}/streams/${streamId}/video`,
          {
            headers,
            responseType: 'blob',
          }
        )
      )
    );
  }

  getMissionVideoSources(missionId: string): Observable<VideoSourceModel[]> {
    const mission$ = this.fetchMissionService.getMission(missionId);
    const users$ = this.userService.getUserList();

    return combineLatest([mission$, users$]).pipe(
      distinctUntilChanged((a, b) => isEqual(a[0].streams, b[0].streams)),
      map(([mission, users]) => {
        const boxAndMobileStreams = Object.entries(mission.streams || [])
          .map<Keyed<MissionStream>>(([id, stream]) => ({ key: id, ...stream }))
          .filter((s) => s.deleted == null || s.deleted === false);

        const mobileStreams = boxAndMobileStreams
          .filter((stream) => !stream.muxer)
          .filter(hasStreamsStart);

        const mobileSources = chain(mobileStreams)
          .filter((stream) => !!stream.author)
          .groupBy((stream) => `${stream.author}${stream.sourceDevice}`)
          .values()
          .filter((streamsByDevice) => !isEmpty(streamsByDevice))
          .map<VideoSourceModel>((streamsByDevice) => {
            const firstStream = first(streamsByDevice)!;
            const author = firstStream.author!;
            const authorName = users.find((u) => u.uid === firstStream.author)?.name ?? 'Unknown';
            const sourceDevice = firstStream.sourceDevice ?? null;

            const streams = streamsByDevice
              .sort((stream1, stream2) => stream2.streamStart - stream1.streamStart)
              .map<VideoStreamModel>((stream) => ({
                id: stream.key,
                dashUrl: `${this.protocol}://${stream.dashUrl}`,
                hlsUrl: `${this.protocol}://${stream.hlsUrl}`,
                streamStart: stream.streamStart,
                streamEnd: stream.streamEnd,
                author,
                device: sourceDevice ?? 'Unknown device',
                isActive: !!stream.isActive,
                streamType: stream.streamType,
                hardware: stream.hardware,
              }));

            return new VideoSourceModel(author, 'mobile', authorName, sourceDevice, streams);
          })
          .value();

        //return mobileSources.filter(isNotNil).sort((s1, s2) => s1.title.localeCompare(s2.title));
        let filteredMobileSources = mobileSources.filter(isNotNil).sort((s1, s2) => s1.title.localeCompare(s2.title));
        console.log('DEBUG filteredMobileSources:', filteredMobileSources);
        return filteredMobileSources;
      })
    );
  }
}
