import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbAuthModule } from '@nebular/auth';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { OAuthModule } from 'angular-oauth2-oidc';
import { Dictionary } from 'lodash';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigService } from './app.config.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { SharedComponentsModule } from './pages/shared-components/shared-components.module';
import { PresenceService } from './services/presense.service';

export function initializeAppConfig(appConfig: AppConfigService): () => Promise<void> {
  return () => appConfig.loadAppConfig();
}

export function initializeFirebaseOptions(appConfig: AppConfigService): Dictionary<any> {
  return appConfig.firebaseConfig;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,

    NbEvaIconsModule,

    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),

    AngularFireModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,

    NbAuthModule.forRoot(),
    ThemeModule.forRoot(),

    SharedComponentsModule,
    AuthenticationModule,
    OAuthModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppConfig,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: FIREBASE_OPTIONS,
      useFactory: initializeFirebaseOptions,
      deps: [AppConfigService],
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    PresenceService,
  ],
})
export class AppModule {}
