/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import Hls, {
  ErrorData,
  ErrorDetails,
  ErrorTypes,
  Events,
  FragChangedData,
  Fragment,
  LevelLoadedData,
  LevelUpdatedData,
  MediaAttachedData,
} from 'hls.js';
import {
  BehaviorSubject,
  combineLatest,
  fromEventPattern,
  interval,
  merge,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import {
  audit,
  count,
  distinctUntilChanged,
  filter,
  first,
  flatMap,
  map,
  pairwise,
  scan,
  share,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  throttleTime,
  windowTime,
  withLatestFrom,
} from 'rxjs/operators';
import { filterForNotIsNil, isNil } from 'src/utils/is-not-nil';
import { logger } from 'src/utils/logger';
import { Destroyable, MixinRoot } from 'src/utils/mixins';
import { AppConfigService } from '../app.config.service';
import { VideoStreamModel } from './muxer.service';
import { StateService } from './state.service';

const PLAYBACK_RATE = '[playback-rate]:';
const NETWORK_ERROR_RECOVER_TIMEOUT = 2000;
const MEDIA_ERROR_RECOVER_TIMEOUT = 2000;

@Injectable()
export class VideoPlayerService extends Destroyable(MixinRoot) {
  constructor(
    private auth: AngularFireAuth,
    private state: StateService,
    private appConfig: AppConfigService
  ) {
    super();

    //#region Handle Errors
    this.error$.pipe(filter((error) => error.fatal)).subscribe((error) => {
      console.error(error);
    });

    this.error$
      .pipe(
        filter(
          (error) =>
            // NOTE: when error is BUFFER_STALLED_ERROR
            // video play successfully without recovering
            // while recover breaks video playback
            error.type === ErrorTypes.MEDIA_ERROR &&
            error.details !== ErrorDetails.BUFFER_STALLED_ERROR &&
            error.details !== ErrorDetails.BUFFER_NUDGE_ON_STALL
        ),
        throttleTime(MEDIA_ERROR_RECOVER_TIMEOUT),
        withLatestFrom(this.hls$)
      )
      .subscribe(([error, hls]) => {
        logger.log(error);
        hls.recoverMediaError();
      });

    this.error$
      .pipe(
        filter((error) => error.type === ErrorTypes.NETWORK_ERROR),
        throttleTime(NETWORK_ERROR_RECOVER_TIMEOUT),
        withLatestFrom(this.hls$)
      )
      .subscribe(([error, hls]) => {
        logger.log(error);
        hls.startLoad();
      });
    //#endregion

    //#region Catch up
    const media$ = this.hls$.pipe(
      switchMap((hls) =>
        fromEventPattern<[string, MediaAttachedData]>(
          (handler) => hls.on(Events.MEDIA_ATTACHED, handler),
          (handler) => hls.off(Events.MEDIA_ATTACHED, handler)
        )
      ),
      map(([_, { media }]) => media),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    const mediaDetaching$ = this.hls$.pipe(
      switchMap((hls) =>
        fromEventPattern<void>(
          (handler) => hls.on(Events.MEDIA_DETACHING, handler),
          (handler) => hls.off(Events.MEDIA_DETACHING, handler)
        )
      )
    );

    const DEFAULT_PROGRAM_DATE_TIME_DELTA = 0; // ms
    const BOX_STREAM_PROGRAM_DATE_TIME_DELTA = 4500; // ms
    const ANDROID_STREAM_PROGRAM_DATE_TIME_DELTA = 6500; // ms

    const defaultDelta = (stream: VideoStreamModel | null) => {
      if (stream?.hardware === 'ios') {
        return DEFAULT_PROGRAM_DATE_TIME_DELTA;
      } else if (stream?.hardware === 'android') {
        return ANDROID_STREAM_PROGRAM_DATE_TIME_DELTA;
      } else if (stream?.hardware === 'box') {
        return BOX_STREAM_PROGRAM_DATE_TIME_DELTA;
      } else {
        return DEFAULT_PROGRAM_DATE_TIME_DELTA;
      }
    };

    const deltaCalculator = (stream: VideoStreamModel | null, firstFragment: Fragment | null) => {
      if (isNil(stream) || isNil(firstFragment)) {
        return defaultDelta(stream);
      }
      if (isNil(stream.streamStart) || isNil(firstFragment.programDateTime)) {
        return defaultDelta(stream);
      }
      if (stream.streamStart > firstFragment.programDateTime) {
        return defaultDelta(stream);
      }

      const delta = firstFragment.programDateTime - stream.streamStart;

      if (stream.hardware === 'ios') {
        return delta;
      } else if (stream.hardware === 'android') {
        return delta > 2000 ? delta - 2000 : delta;
      } else if (stream.hardware === 'box') {
        return delta > 2000 ? delta - 2000 : delta;
      } else {
        return DEFAULT_PROGRAM_DATE_TIME_DELTA;
      }
    };

    const fragmentDeltaDateTime$ = this.hls$.pipe(
      switchMap((hls) =>
        fromEventPattern<[string, LevelLoadedData]>(
          (handler) => hls.on(Events.LEVEL_LOADED, handler),
          (handler) => hls.off(Events.LEVEL_LOADED, handler)
        ).pipe(first())
      ),
      withLatestFrom(this.state.videoStream),
      map(([[_, { details }], stream]) => {
        const delta = deltaCalculator(stream, details.fragments[0]);
        console.log(
          [
            `${PLAYBACK_RATE} Calculating delta...`,
            `${PLAYBACK_RATE} stream?.streamStart = ${stream?.streamStart}`,
            `${PLAYBACK_RATE} zeroFragment.programDateTime = ${details.fragments[0].programDateTime}`,
            `${PLAYBACK_RATE} Delta calculated = ${delta}`,
          ].join('\n')
        );
        return delta;
      })
    );

    const fragmentStartDateTime$ = this.hls$.pipe(
      switchMap((hls) =>
        fromEventPattern<[string, FragChangedData]>(
          (handler) => hls.on(Events.FRAG_CHANGED, handler),
          (handler) => hls.off(Events.FRAG_CHANGED, handler)
        ).pipe(
          map(([_, { frag }]) => frag.programDateTime),
          filterForNotIsNil()
        )
      ),
      withLatestFrom(fragmentDeltaDateTime$),
      map(([programDateTime, delta]) => programDateTime - delta)
    );

    // Time difference between timeupdate events in milliseconds
    const timeUpdateDelta$ = media$.pipe(
      switchMap((media) =>
        fromEventPattern<Event>(
          (handler) => media.addEventListener('timeupdate', handler),
          (handler) => media.removeEventListener('timeupdate', handler)
        ).pipe(
          map((event) => (event.target as HTMLVideoElement).currentTime),
          pairwise(),
          map(([t1, t2]) => t2 - t1),
          map((delta) => Math.floor(delta * 1000)),
          takeUntil(mediaDetaching$)
        )
      )
    );

    const playbackRate$ = media$.pipe(
      switchMap((media) =>
        fromEventPattern<Event>(
          (handler) => media.addEventListener('ratechange', handler),
          (handler) => media.removeEventListener('ratechange', handler)
        ).pipe(
          map((event) => (event.target as HTMLVideoElement).playbackRate),
          startWith(1),
          takeUntil(mediaDetaching$)
        )
      )
    );

    // Latency in seconds
    const latency$ = fragmentStartDateTime$.pipe(
      switchMap((fragmentStartDateTime) =>
        timeUpdateDelta$.pipe(
          scan(
            (fragmentTime, timeUpdateDelta) => fragmentTime + timeUpdateDelta,
            fragmentStartDateTime
          )
        )
      ),
      map((fragmentTime) => (Date.now() - fragmentTime) / 1000)
    );

    const bufferStalled$ = this.hls$.pipe(
      switchMap((hls) =>
        fromEventPattern<[string, ErrorData]>(
          (handler) => hls.on(Events.ERROR, handler),
          (handler) => hls.off(Events.ERROR, handler)
        )
      ),
      filter(([_, { details }]) => details === ErrorDetails.BUFFER_STALLED_ERROR),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    const MEASURE_PERIOD = 30 * 1000;
    const START_NEW_MEASURE_INTERVAL = 1000;
    const DECREASE_COMMAND_INTERVAL = 10 * 1000;
    const increaseMaxLatency$: Observable<'increase'> = bufferStalled$.pipe(map(() => 'increase'));
    const decreaseMaxLatency$: Observable<'decrease'> = bufferStalled$.pipe(
      windowTime(MEASURE_PERIOD, START_NEW_MEASURE_INTERVAL), // Calculate moving average of BUFFER_STALLED_ERROR per MEASURE_PERIOD seconds
      flatMap((errors) => errors.pipe(count())),
      filter((errorsCount) => errorsCount <= 1),
      throttleTime(DECREASE_COMMAND_INTERVAL),
      map(() => 'decrease')
    );

    const MIN_LATENCY = 4;
    const MAX_LATENCY = 10;
    const targetLatency$ = merge(increaseMaxLatency$, decreaseMaxLatency$).pipe(
      scan((targetLatency, action) => {
        if (action === 'increase' && targetLatency < MAX_LATENCY) {
          console.log(
            `${PLAYBACK_RATE} Stall detected, adjusting targetLatency to ${targetLatency + 1}`
          );
          return targetLatency + 1;
        }
        if (action === 'decrease' && targetLatency > MIN_LATENCY) {
          console.log(`${PLAYBACK_RATE} Adjusting targetLatency to ${targetLatency - 1}`);
          return targetLatency - 1;
        }
        return targetLatency;
      }, MIN_LATENCY),
      distinctUntilChanged(),
      startWith(MIN_LATENCY),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    const NORMAL_RATE = 1;
    const HIGH_RATE = 1.5;
    const NORMAL_RATE_LATENCY_MEASURE_PERIOD = 5000;
    const HIGH_RATE_LATENCY_MEASURE_PERIOD = 500;

    this.hls$
      .pipe(
        switchMap((_) =>
          combineLatest([latency$, playbackRate$]).pipe(
            audit(([_latency, playbackRate]) => {
              if (playbackRate === 1) {
                return interval(NORMAL_RATE_LATENCY_MEASURE_PERIOD);
              }
              return interval(HIGH_RATE_LATENCY_MEASURE_PERIOD);
            }),
            withLatestFrom(this.isLiveStream$),
            filter(([[_latency, _playbackRate], isLiveStream]) => isLiveStream),
            withLatestFrom(targetLatency$, this.video$),
            takeUntil(this.destroyed$)
          )
        )
      )
      .subscribe(([[[latency, _playbackRate]], targetLatency, video]) => {
        const distance = latency - targetLatency;
        if (distance > 0 && distance < 2 * MAX_LATENCY) {
          video.playbackRate = HIGH_RATE;
        } else {
          video.playbackRate = NORMAL_RATE;
        }
        console.log(
          [
            `${PLAYBACK_RATE} RATE`,
            `live latency:  ${latency}`,
            `target latency:${targetLatency}`,
            `rate set to:   ${video.playbackRate}`,
          ].join('\n')
        );
      });
    //#endregion
  }

  private video = new ReplaySubject<HTMLVideoElement>(1);

  setVideo(video: HTMLVideoElement) {
    this.video.next(video);
  }

  video$ = this.video.asObservable();

  hls$ = combineLatest([
    this.auth.idToken,
    of(this.appConfig.apiKey),
    this.state.videoStreamId,
  ]).pipe(
    switchMap(
      ([authToken, apiKey, _streamId]) =>
        new Observable<Hls>((subscriber) => {
          const hls = new Hls({
            enableWorker: true,
            xhrSetup(xhr, _url) {
              xhr.setRequestHeader('Authorization', `Bearer ${authToken}`);
              xhr.setRequestHeader('X-API-Key', `${apiKey ?? ''}`);
            },
          });

          subscriber.next(hls);

          return () => {
            hls.destroy();
          };
        })
    ),
    takeUntil(this.destroyed$),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  error$ = this.hls$.pipe(
    switchMap((hls) =>
      fromEventPattern<[string, ErrorData]>(
        (handler) => hls.on(Events.ERROR, handler),
        (handler) => hls.off(Events.ERROR, handler)
      )
    ),
    map(([_, error]) => error),
    takeUntil(this.destroyed$),
    share()
  );

  isLiveStream$ = this.hls$.pipe(
    switchMap((hls) =>
      fromEventPattern<[string, LevelUpdatedData]>(
        (handler) => hls.on(Events.LEVEL_UPDATED, handler),
        (handler) => hls.off(Events.LEVEL_UPDATED, handler)
      )
    ),
    map(([_, { details }]) => details.live),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  isUsingWebRtc$ = new BehaviorSubject<boolean>(true);
}
