import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthErrorEvent } from 'angular-oauth2-oidc';
import { PAGES_URLS } from '../../pages/routing/pages-urls';
import { APP_URLS } from '../../routing/app-urls';
import { AuthenticationService } from '../authentication.service';
import { formConfig } from '../form-config';
import { AUTH_ROUTE_QUERY_PARAMS } from '../routing/auth-route-query-params';
import { AUTH_URLS } from '../routing/auth-urls';
import { AppConfigService } from '../../app.config.service';

@Component({
  selector: 'mk-sign-in',
  styleUrls: ['./sign-in.component.scss', '../shared/auth.scss'],
  templateUrl: './sign-in.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent {
  constructor(
    private auth: AuthenticationService,
    private appConfig: AppConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private ref: ChangeDetectorRef
  ) {
    this.title.setTitle('Sign in - Mission Keeper');
  }

  submitted = false;
  errors: string[] = [];
  user = { email: '', password: '' };
  validation = formConfig.validation;
  message: string | undefined = this.route.snapshot.queryParams[AUTH_ROUTE_QUERY_PARAMS.MESSAGE];
  alreadyRegistered = !!this.route.snapshot.queryParams[AUTH_ROUTE_QUERY_PARAMS.ALREADY_REGISTERED];
  missionId: string | undefined =
    this.route.snapshot.queryParams[AUTH_ROUTE_QUERY_PARAMS.MISSION_ID];
  organizationId: string | undefined =
    this.route.snapshot.queryParams[AUTH_ROUTE_QUERY_PARAMS.ORGANIZATION_ID];
  isFlynexEnabled = this.appConfig.oauthFlynexConfig.isEnabled === 'true'

  get resetPasswordLink() {
    return AUTH_URLS.RESET_PASSWORD();
  }

  async signIn(provider: 'password' | 'flynex' = 'password'): Promise<void> {
    this.errors = [];
    this.submitted = true;

    try {
      const credentials =
        provider === 'password'
          ? await this.auth.signIn(this.user.email, this.user.password)
          : await this.auth.signInFlynex();

      if (this.alreadyRegistered) {
        await this.auth.updateSignUpData(
          credentials.user!.uid,
          this.organizationId!,
          this.missionId
        ); // TODO: remove ! assertion
      }
      if (this.missionId) {
        await this.router.navigate(PAGES_URLS.MISSION(this.missionId));
      } else {
        await this.router.navigateByUrl(
          this.route.snapshot.queryParams[AUTH_ROUTE_QUERY_PARAMS.RETURN_URL] ?? APP_URLS.ROOT()
        );
      }
    } catch (err: any) {
      console.error(err);

      if (err instanceof OAuthErrorEvent) {
        if (err.type === 'popup_closed' || err.type === 'popup_blocked') {
          return;
        }
      }

      if (err instanceof HttpErrorResponse) {
        if (err.error) {
          this.errors = [err.error.error_description];
          return;
        }
      }

      this.errors = [err];
    } finally {
      this.submitted = false;
      this.ref.markForCheck();
    }
  }
}
