import { pad } from 'lodash';

export type LogLevel = keyof Pick<Console, 'trace' | 'debug' | 'log' | 'info' | 'warn' | 'error'>;

export type LogFunc = (...args: any) => void;

export type DebugConfig = Partial<Record<LogLevel, LogFunc | boolean>> | true;

function getSelfScope() {
  // see https://stackoverflow.com/a/11237259/589493
  if (typeof window === 'undefined') {
    /* eslint-disable-next-line no-undef */
    return self;
  } else {
    return window;
  }
}

function noop(...args: any[]) {}

const fakeLogger: Record<LogLevel, LogFunc> = {
  trace: noop,
  debug: noop,
  log: noop,
  warn: noop,
  info: noop,
  error: noop,
};

let exportedLogger = fakeLogger;

function formatMsg(level: LogLevel, msg: any) {
  return `[${pad(level, 5)}] > ${msg}`;
}

const global = getSelfScope();

function consolePrintFn(level: LogLevel) {
  const func = global.console[level];
  if (func) {
    return (...args: any[]) => {
      if (args[0]) {
        args[0] = formatMsg(level, args[0]);
      }

      func.apply(global.console, args);
    };
  }
  return noop;
}

function exportLoggerFunctions(debugConfig: DebugConfig, ...levels: LogLevel[]) {
  levels.forEach((level) => {
    if (typeof debugConfig === 'boolean') {
      exportedLogger[level] = consolePrintFn(level);
    } else {
      const logger = debugConfig[level];
      if (logger === undefined) {
        exportedLogger[level] = noop;
      } else if (typeof logger === 'boolean') {
        exportedLogger[level] = logger === true ? consolePrintFn(level) : noop;
      } else {
        exportedLogger[level] = logger.bind(debugConfig);
      }
    }
  });
}

export const enableLogs = (debugConfig: DebugConfig) => {
  // check that console is available
  if ((global.console && debugConfig === true) || typeof debugConfig === 'object') {
    exportLoggerFunctions(
      debugConfig,
      // Remove out from list here to hard-disable a log-level
      // 'trace',
      'debug',
      'log',
      'info',
      'warn',
      'error'
    );
    // Some browsers don't allow to use bind on console object anyway
    // fallback to default if needed
    try {
      exportedLogger.log();
    } catch (e) {
      exportedLogger = fakeLogger;
    }
  } else {
    exportedLogger = fakeLogger;
  }
};

(window as any).__enable_mk_logs__ = enableLogs;

export const logger = exportedLogger;
