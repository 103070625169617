<nb-card>
  <nb-card-body>
    <div class="authors" *ngIf="videoSources$ | async as videoSources">
      <div class="author" *ngFor="let source of videoSources; trackBy: trackById">
        <nb-icon [ngStyle]="{ color: source.isActive ? '#28a745' : '#0096d7' }" class="video-icon"
          icon="video"></nb-icon>
        <div>
          <div class="author-title">{{ source.title }}</div>
          <div class="author-device">{{ source.device }}</div>
        </div>
      </div>
    </div>

    <div class="sources-wrapper has-scroll" *ngIf="videoSources$ | async as videoSources">
      <div class="sources">
        <div class="streams debugStreamSource" *ngFor="let source of videoSources; trackBy: trackById">
          <div *ngFor="let stream of source.streams; let idx = index; trackBy: trackById" class="stream debugStream"
            [nbPopover]="streamPopover" nbPopoverTrigger="hint" [nbPopoverContext]="{ source: source, stream: stream }"
            (click)="streamClicked$.next(stream)">
            <mk-stream-context-menu [stream]="stream" class="debugStreamMenu">
              <span class="active-badge ersBadge" *ngIf="stream.isActive">
              </span>
              <span class="debugStreamDetails"
                [ngClass]=" { 'stream-title': true, 'currently-playing': (selectedStreamId$ | async) === stream.id }">
                <span *ngIf="stream.streamStart; then hasStart; else hasNoStart"></span>
                <ng-template #hasStart>{{ stream.streamStart | date: 'short' }}</ng-template>
                <ng-template #hasNoStart>Stream {{ idx }}</ng-template>
              </span>
            </mk-stream-context-menu>

            <!--P2M-1095 START-->
            <span class="debugStreamRealTime" *ngIf="stream.isActive">
              <span class="active-badge debugStreamBadge"></span>
              <span class="debugStreamDetails stream-title"
                [ngClass]=" { 'currently-playing': (selectedStreamId$ | async) === stream.id }">
                <span *ngIf="stream.streamStart; then hasStart; else hasNoStart"></span>
                <ng-template #hasStart>{{ stream.streamStart | date: 'short' }}</ng-template>
                <ng-template #hasNoStart>Stream {{ idx }}</ng-template>
              </span>
            </span>
            <!--P2M-1095 END-->
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<ng-template #streamPopover let-context>
  <nb-list>
    <nb-list-item class="popover-item">{{ context.source.title }}</nb-list-item>
    <nb-list-item class="popover-item" *ngIf="context.source.device != null">{{ context.source.device }}</nb-list-item>
    <nb-list-item class="popover-item" *ngIf="context.stream.streamStart != null">Start: {{ context.stream.streamStart |
      date: 'medium' }}</nb-list-item>
    <nb-list-item class="popover-item" *ngIf="context.stream.streamEnd != null">End: {{ context.stream.streamEnd | date:
      'medium' }}</nb-list-item>
    <nb-list-item class="popover-item" *ngIf="context.stream.streamEnd == null">End: In Progress</nb-list-item>
  </nb-list>
</ng-template>