import { OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';
import lodashIsNil from 'lodash/isNil';

export function isNil<T>(value: T | null | undefined): value is null | undefined {
  return lodashIsNil(value);
}

export function isNotNil<T>(value: T | null | undefined): value is NonNullable<T> {
  return !lodashIsNil(value);
}

export function filterForNotIsNil<T>(): OperatorFunction<T, NonNullable<T>> {
  return (source) => source.pipe(filter(isNotNil));
}
