import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { filter, first, pairwise } from 'rxjs/operators';
import { RegisterUserRequest, UserRole } from '../models/user';
import { DatabaseService } from '../services/database.service';
import { IdentityServerService } from '../services/identity-server.service';
import { OAuthWrapperService } from '../services/oauth-wrapper.service';
import { ProfileService } from '../services/profile.service';
import { UserService } from '../services/user.service';
import { AUTH_ROUTE_QUERY_PARAMS } from './routing/auth-route-query-params';
import { AUTH_URLS } from './routing/auth-urls';
import { isNotNil } from '../../utils/is-not-nil';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private auth: AngularFireAuth,
    private oauthService: OAuthWrapperService,
    private db: DatabaseService,
    private profile: ProfileService,
    private router: Router,
    private identityServer: IdentityServerService,
    private userService: UserService
  ) {
    this.auth.user
      .pipe(
        pairwise(),
        filter(([fromUser, toUser]) => !!fromUser && toUser == null)
      )
      .subscribe(() => {
        this.router.navigate(AUTH_URLS.SIGN_IN());
      });
  }

  async signIn(email: string, password: string) {
    await this.oauthService.loginPasswordFlow(email, password);
    return this.signInToFirebaseWithCustomToken();
  }

  async signInFlynex() {
    await this.oauthService.loginFlynexFlow();
    return this.signInToFirebaseWithCustomToken();
  }

  updateSignUpData(uid: string, organizationId: string, missionId?: string) {
    const userRole = missionId ? UserRole.Guest : UserRole.PendingApproval;
    return Promise.all([
      this.db.user(uid).organization(organizationId).setRole(userRole),
      this.db.organization(organizationId).user(uid).set(),
      missionId ? this.db.user(uid).mission(missionId).set() : Promise.resolve(),
    ]);
  }

  async signUp(
    email: string,
    password: string,
    fullName: string,
    organizationId: string,
    missionId?: string
  ): Promise<void> {
    const authMethods = await this.auth.fetchSignInMethodsForEmail(email);
    const alreadyRegistered = authMethods.some((m) => m === 'password');

    if (alreadyRegistered) {
      await this.router.navigate(AUTH_URLS.SIGN_IN(), {
        queryParams: {
          [AUTH_ROUTE_QUERY_PARAMS.MESSAGE]: `We've detected you already have an account with Mission Keeper.`,
          [AUTH_ROUTE_QUERY_PARAMS.MISSION_ID]: missionId,
          [AUTH_ROUTE_QUERY_PARAMS.ORGANIZATION_ID]: organizationId,
          [AUTH_ROUTE_QUERY_PARAMS.ALREADY_REGISTERED]: true,
        },
      });

      return;
    }

    const registerUserRequest = {
      email,
      password,
      name: fullName,
      invitedOrg: organizationId,
      mission: missionId,
      tos: true,
    } as RegisterUserRequest;

    try {
      await this.userService.registerUser(registerUserRequest);
    } catch {
      throw new Error('Sign up failed');
    }
  }

  logout() {
    this.oauthService.signOut();
    return this.auth.signOut();
  }

  resetPassword(email: string): Promise<void> {
    return this.identityServer.resetPassword(email);
  }

  async acceptTos() {
    const uid = await this.profile.userId.pipe(first(isNotNil)).toPromise();
    return this.db.users.update({
      uid,
      tos: true,
    });
  }

  private async signInToFirebaseWithCustomToken() {
    const firebaseCustomToken = await this.identityServer.getFirebaseCustomToken();
    return this.auth.signInWithCustomToken(firebaseCustomToken!);
  }
}
